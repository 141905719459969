var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _vm.show
      ? _c(
          "div",
          { staticClass: "bgback" },
          [_c("van-loading", { attrs: { size: "68" } })],
          1
        )
      : _vm._e(),
    !_vm.show
      ? _c("div", { staticClass: "topcenters" }, [
          _c("div", { staticClass: "topcenter " }, [
            _vm._m(0),
            _c("div", { staticClass: "user" }, [
              _c("div", { staticClass: "labelUser" }, [_vm._v("客户姓名：")]),
              _c("span", [_vm._v(_vm._s(_vm.uesername.name))]),
              _c("div", { staticClass: "labelUser" }, [_vm._v("联系电话：")]),
              _c("span", [_vm._v(_vm._s(_vm.uesername.phone))]),
              _c("div", { staticClass: "labelUser" }, [_vm._v("顺丰单号：")]),
              _c("span", [_vm._v(_vm._s(_vm.uesername.orderNoExpress))])
            ]),
            _vm.wuLiaoOrdershow
              ? _c(
                  "table",
                  { staticClass: "tablebox" },
                  [
                    _vm._m(1),
                    _c("tr", { staticClass: "sellbox tl padtr" }, [
                      _c("th", { attrs: { colspan: "6" } }, [
                        _c("div", { staticClass: "labelth" }, [
                          _vm._v("开单时间：")
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.wuLiaoOrder.date))]),
                        _c("div", { staticClass: "labelth" }, [
                          _vm._v("物料单号：")
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.wuLiaoOrder.orderNoLaiLiao))
                        ])
                      ])
                    ]),
                    _vm._m(2),
                    _vm._l(_vm.wuLiaoOrder.itemList, function(items, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _c("td", [_vm._v(_vm._s(items.name))]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                items.name.includes("黄金")
                                  ? "黄金板料"
                                  : items.weight
                              ) +
                              " "
                          )
                        ]),
                        _c("td", [_vm._v(_vm._s(items.weightRongHou))]),
                        _c("td", [_vm._v(_vm._s(items.percentage))]),
                        _c("td", [_vm._v(_vm._s(items.weightZeZu))])
                      ])
                    }),
                    _vm._m(3),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("原重量")]),
                      _c("td", [_vm._v(_vm._s(_vm.wuLiaoOrder.weightTotal))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("熔后克重")]),
                      _c("td", [_vm._v(_vm._s(_vm.wuLiaoOrder.weightRongHou))]),
                      _c("td", { staticClass: "bggray" }, [
                        _vm._v("折算总重量")
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.wuLiaoOrder.weightZeZu))])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("备注")]),
                      _c("td", { attrs: { colspan: "5" } }, [
                        _c("span", { staticClass: "f26" }, [
                          _vm._v(_vm._s(_vm.wuLiaoOrder.intro))
                        ])
                      ])
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _vm.saleOrdershow
              ? _c(
                  "table",
                  { staticClass: "tablebox" },
                  [
                    _vm._m(4),
                    _c("tr", { staticClass: "sellbox padtr tl" }, [
                      _c("th", { attrs: { colspan: "9" } }, [
                        _c("div", { staticClass: "labelth" }, [
                          _vm._v("开单时间：")
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.wuLiaoOrder.date))]),
                        _c("div", { staticClass: "labelth" }, [
                          _vm._v("物料单号：")
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.wuLiaoOrder.orderNoLaiLiao))
                        ])
                      ])
                    ]),
                    _vm._m(5),
                    _vm._l(_vm.saleOrder.itemList, function(items, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _c("td", [_vm._v(_vm._s(items.name))]),
                        _c("td", [_vm._v(_vm._s(items.weightZeZu))]),
                        _c("td", [_vm._v(_vm._s(items.price))]),
                        _c("td", [_vm._v(_vm._s(items.money))]),
                        _c("td", [_vm._v(_vm._s(items.status))]),
                        _c("td", [_vm._v(_vm._s(items.priceKind))]),
                        _c("td", [_vm._v(_vm._s(items.date))])
                      ])
                    }),
                    _vm._m(6),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("折足总重")]),
                      _c("td", [_vm._v(_vm._s(_vm.saleOrder.weightZeZu))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("其它费用")]),
                      _c("td", [_vm._v(_vm._s(_vm.saleOrder.moneyTiChun))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("快递费")]),
                      _c("td", [_vm._v(_vm._s(_vm.saleOrder.moneyYunFei))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("保险费")]),
                      _c("td", [_vm._v(_vm._s(_vm.saleOrder.moneyBaoXiang))])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("保管费")]),
                      _c("td", [_vm._v(_vm._s(_vm.saleOrder.moneyStorageFee))]),
                      _c("td", { staticClass: "bggray" }, [_vm._v("合计金额")]),
                      _c("td", { attrs: { colspan: "7" } }, [
                        _c("span", { staticClass: "f26" }, [
                          _vm._v(_vm._s(_vm.saleOrder.moneyTotal))
                        ])
                      ])
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "bggray" }, [_vm._v("备注")]),
                      _c("td", { attrs: { colspan: "8" } }, [
                        _c("span", { staticClass: "f26" }, [
                          _vm._v(_vm._s(_vm.saleOrder.intro))
                        ])
                      ])
                    ])
                  ],
                  2
                )
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tiptop" }, [
      _c("i", { staticClass: "el-icon-warning-outline" }),
      _vm._v("用户可横向滚动查看更多详情")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "sellbox" }, [
      _c("th", { staticClass: "fb f26", attrs: { colspan: "6" } }, [
        _vm._v("物料明细单")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "bggray" }, [
      _c("th", { staticClass: "indexWidth" }, [_vm._v("序号")]),
      _c("th", { staticClass: "styleth" }, [_vm._v("类别")]),
      _c("th", { staticClass: "styleth" }, [_vm._v("折算材质")]),
      _c("th", { staticClass: "styleth" }, [_vm._v("熔后克重")]),
      _c("th", { staticClass: "styleth" }, [_vm._v("折算比例")]),
      _c("th", { staticClass: "styleth" }, [_vm._v("折足克重")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "6" } })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "sellbox" }, [
      _c("th", { staticClass: "fb f26", attrs: { colspan: "8" } }, [
        _vm._v("卖出结算单")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "bggray" }, [
      _c("th", [_vm._v("序号")]),
      _c("th", [_vm._v("类别")]),
      _c("th", [_vm._v("折足克重")]),
      _c("th", [_vm._v("结算价格")]),
      _c("th", [_vm._v("金额")]),
      _c("th", [_vm._v("状态")]),
      _c("th", [_vm._v("定价渠道")]),
      _c("th", [_vm._v("定价时间")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { attrs: { colspan: "9" } })])
  }
]
render._withStripped = true

export { render, staticRenderFns }